import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

import Login from '@/pages/Login/Login.vue'
import ErrorPage from '@/pages/Error/Error.vue'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from './store/SystemModule'
import MfaAlert from './plugins/mfa-alert'

// Layout
const Layout = () => import('@/components/Layout/Layout.vue')

// Insights
// const InsightsLegacy = () => import(/* webpackChunkName: "chunk-insights-legacy" */ '@/pages/InsightsLegacy/Insights.vue'); // Legacy Insights
const Insights = () => import(/* webpackChunkName: "chunk-insights" */ '@/pages/Insights/index.vue')

// Company
const CompanyHome = () =>
  import(/* webpackChunkName: "chunk-companies" */ '@/pages/Company/CompanyHome.vue')
const CompanyEdit = () =>
  import(/* webpackChunkName: "chunk-companies" */ '@/pages/Company/CompanyEdit.vue')

// Account
const UserAccount = () =>
  import(/* webpackChunkName: "chunk-account" */ '@/pages/User/UserAccount.vue')

// Users
const UserHome = () => import(/* webpackChunkName: "chunk-users" */ '@/pages/User/UserHome.vue')
const UserEdit = () => import(/* webpackChunkName: "chunk-users" */ '@/pages/User/UserEdit.vue')

// Profile
const ProfileHome = () =>
  import(/* webpackChunkName: "chunk-profile" */ '@/pages/Profile/ProfileHome.vue')
const ProfileEdit = () =>
  import(/* webpackChunkName: "chunk-profile" */ '@/pages/Profile/ProfileEdit.vue')

// Reports
const ReportHome = () =>
  import(/* webpackChunkName: "chunk-reports" */ '@/pages/Report/ReportHome.vue')
const ReportEdit = () =>
  import(/* webpackChunkName: "chunk-reports" */ '@/pages/Report/ReportEdit.vue')

// Terms
const TermsHome = () => import(/* webpackChunkName: "chunk-terms" */ '@/pages/Terms/TermsHome.vue')
const TermsEdit = () => import(/* webpackChunkName: "chunk-terms" */ '@/pages/Terms/TermsEdit.vue')

// Invoice
const InvoiceHome = () =>
  import(/* webpackChunkName: "chunk-invoice" */ '@/pages/Invoice/InvoiceHome.vue')
const InvoiceEdit = () =>
  import(/* webpackChunkName: "chunk-invoice" */ '@/pages/Invoice/InvoiceEdit.vue')
const InvoiceView = () =>
  import(/* webpackChunkName: "chunk-invoice" */ '@/pages/Invoice/InvoiceView.vue')

// Agreement
const AgreementHome = () =>
  import(/* webpackChunkName: "chunk-agreement" */ '@/pages/Agreement/AgreementHome.vue')
const AgreementEdit = () =>
  import(/* webpackChunkName: "chunk-agreement" */ '@/pages/Agreement/AgreementEdit.vue')

// Inventory
const InventoryHome = () =>
  import(/* webpackChunkName: "chunk-inventory" */ '@/pages/Inventory/InventoryHome.vue')
const InventoryEdit = () =>
  import(/* webpackChunkName: "chunk-inventory" */ '@/pages/Inventory/InventoryEdit.vue')

// ReportView
const ReportViewHome = () =>
  import(/* webpackChunkName: "chunk-view" */ '@/pages/ReportView/ReportViewHome.vue')
const ReportViewEdit = () =>
  import(/* webpackChunkName: "chunk-view" */ '@/pages/ReportView/ReportViewEdit.vue')

// Creative
const CreativeHome = () =>
  import(/* webpackChunkName: "chunk-creative" */ '@/pages/Creative/CreativeHome.vue')
const CreativeEdit = () =>
  import(/* webpackChunkName: "chunk-creative" */ '@/pages/Creative/CreativeEdit.vue')

// Forecast
const ForecastHome = () =>
  import(/* webpackChunkName: "chunk-forecast" */ '@/pages/Forecast/ForecastHome.vue')
const ForecastUpdate = () =>
  import(/* webpackChunkName: "chunk-forecast" */ '@/pages/Forecast/ForecastUpdate.vue')

// Audit
const AuditHome = () => import(/* webpackChunkName: "chunk-audit" */ '@/pages/Audit/AuditHome.vue')

// Adunit Scheduler
const AdunitSchedulerHome = () =>
  import(
    /* webpackChunkName: "chunk-scheduler" */ '@/pages/AdunitScheduler/AdunitSchedulerHome.vue'
  )

// Adunit Scheduler
const AdunitSchedulerEdit = () =>
  import(
    /* webpackChunkName: "chunk-scheduler" */ '@/pages/AdunitScheduler/AdunitSchedulerEdit.vue'
  )

// Unsubscribe
const Unsubscribe = () =>
  import(/* webpackChunkName: "chunk-unsubscribe" */ '@/pages/Unsubscribe/Unsubscribe.vue')

const MediaOceanEdit = () =>
  import(
    /* webpackChunkName: "chunk-mediaocean" */ '@/pages/MediaOcean/Invoice/MediaOceanInvoiceEdit.vue'
  )
const MediaOceanHome = () =>
  import(
    /* webpackChunkName: "chunk-mediaocean" */ '@/pages/MediaOcean/Invoice/MediaOceanInvoiceHome.vue'
  )

const AdOps = () => import(/* webpackChunkName: "chunk-ops" */ '@/pages/AdOps/ScheduleHome.vue')
const AdOpsDistribution = () =>
  import(
    /* webpackChunkName: "chunk-adops-distribution" */ '@/pages/AdOps/InventoryDistribution.vue'
  )

const MediaPackageHome = () =>
  import(
    /* webpackChunkName: "chunk-sales-paackages" */ '@/pages/Sales/Packages/MediaPackageHome.vue'
  )

const MediaPackageEdit = () =>
  import(
    /* webpackChunkName: "chunk-sales-paackages" */ '@/pages/Sales/Packages/MediaPackageEdit.vue'
  )

const MediaPlanHome = () =>
  import(
    /* webpackChunkName: "chunk-sales-media-plan" */ '@/pages/Sales/MediaPlan/MediaPlanHome.vue'
  )

const MediaPlanEdit = () =>
  import(
    /* webpackChunkName: "chunk-sales-media-plan" */ '@/pages/Sales/MediaPlan/MediaPlanEdit.vue'
  )
const MediaPlanView = () =>
  import(
    /* webpackChunkName: "chunk-sales-media-plan" */ '@/pages/Sales/MediaPlan/MediaPlanView.vue'
  )

const LegacyDashboard = () =>
  import(/* webpackChunkName: "chunk-dashboard" */ '@/pages/DashboardLegacy/index.vue')

const TaskHome = () => import(/* webpackChunkName: "chunk-dashboard" */ '@/pages/Task/TaskMonday.vue')

const ReconciliationHome = () =>
  import(
    /* webpackChunkName: "chunk-reconciliation" */ '@/pages/Reconciliation/ReconciliationHome.vue'
  )

const ReconciliationView = () =>
  import(
    /* webpackChunkName: "chunk-reconciliation-view" */ '@/pages/Reconciliation/ReconciliationView.vue'
  )

const DynamicRateHome = () =>
  import(
    /* webpackChunkName: "chunk-dynamic-rate-home" */ '@/pages/DynamicRate/DynamicRateHome.vue'
  )

const DynamicRateView = () =>
  import(
    /* webpackChunkName: "chunk-dynamic-rate-view" */ '@/pages/DynamicRate/DynamicRateView.vue'
  )

const PaymentHome = () =>
  import(/* webpackChunkName: "chunk-payment-home" */ '@/pages/Payments/PaymentsHome.vue')

const PaymentView = () =>
  import(/* webpackChunkName: "chunk-payment-view" */ '@/pages/Payments/PaymentView.vue')
const InvoiceCreateBatch = () =>
  import(
    /* webpackChunkName: "chunk-invoice-create-batch" */ '@/pages/Invoice/InvoiceBatchCreate.vue'
  )

const ExpenseHome = () =>
  import(/* webpackChunkName: "chunk-expense-home" */ '@/pages/Expenses/ExpensesHome.vue')

const ExpenseBatch = () =>
  import(/* webpackChunkName: "chunk-expense-batch" */ '@/pages/Expenses/ExpenseBatchCreate.vue')

const ExpenseView = () =>
  import(/* webpackChunkName: "chunk-expense-view" */ '@/pages/Expenses/ExpenseView.vue')

const ReviewHome = () =>
  import(/* webpackChunkName: "chunk-review-view" */ '@/pages/Invoice/ReviewHome.vue')

const CreditMemoHome = () =>
  import(/* webpackChunkName: "chunk-credit-memo" */ '@/pages/CreditMemos/CreditMemoHome.vue')
const CreditMemoEdit = () =>
  import(/* webpackChunkName: "chunk-credit-memo-edit" */ '@/pages/CreditMemos/CreditMemoEdit.vue')

const SystemParameterHome = () =>
  import(
    /* webpackChunkName: "chunk-system-params-home" */ '@/pages/SystemParams/SystemParamsHome.vue'
  )

const JournalEntryHome = () =>
  import(
    /* webpackChunkName: "chunk-journal-entry-home" */ '@/pages/JournalEntry/JournalEntryHome.vue'
  )

const JournalEntryEdit = () =>
  import(
    /* webpackChunkName: "chunk-journal-entry-edit" */ '@/pages/JournalEntry/JournalEntryEdit.vue'
  )

const OrdersHome = () =>
  import(/* webpackChunkName: "chunk-order-home" */ '@/pages/Order/OrderHome.vue')
const OrdersEdit = () =>
  import(/* webpackChunkName: "chunk-order-edit" */ '@/pages/Order/OrderEdit.vue')

const LineItemEdit = () =>
  import(/* webpackChunkName: "chunk-line-item-edit" */ '@/pages/LineItem/LineItemEdit.vue')

const OrderView = () =>
  import(/* webpackChunkName: "chunk-order-view" */ '@/pages/Order/OrderView.vue')

const LineItemHome = () =>
  import(/* webpackChunkName: "chunk-line-items" */ '@/pages/LineItem/LineItemHome.vue')

const AdserverInspector = () =>
  import(/* webpackChunkName: "chunk-adserver" */ '@/pages/AdserverInspector/AdserverInspector.vue')

const MediaPlanItemHome = () =>
  import(
    /* webpackChunkName: "chunk-mediaplan-item-home" */ '@/pages/MediaPlanItem/MediaPlanItemHome.vue'
  )
const MediaPlanItemEdit = () =>
  import(
    /* webpackChunkName: "chunk-mediaplan-item-edit" */ '@/pages/MediaPlanItem/MediaPlanItemEdit.vue'
  )

const CommissionHome = () =>
  import(/* webpackChunkName: "chunk-commission-home" */ '@/pages/Commission/CommissionHome.vue')

const CommissionEdit = () =>
  import(/* webpackChunkName: "chunk-commission-edit" */ '@/pages/Commission/CommissionEdit.vue')

const CommissionRules = () =>
  import(/* webpackChunkName: "chunk-commission-rukes" */ '@/pages/Commission/CommissionRules.vue')

const CommissionRuleEdit = () =>
  import(
    /* webpackChunkName: "chunk-commission-rule-edit" */ '@/pages/Commission/CommissionRuleEdit.vue'
  )
const PrePlan = () =>
  import(/* webpackChunkName: "chunk-preplan" */ '@/pages/PrePlan/PrePlanGroup.vue')
const PrePlanHome = () =>
  import(/* webpackChunkName: "chunk-preplans" */ '@/pages/PrePlan/PrePlanHome.vue')
const PrePlanConvert = () =>
  import(/* webpackChunkName: "chunk-preplan-convert" */ '@/pages/PrePlan/PrePlanConvert.vue')

const CalendarHome = () =>
  import(/* webpackChunkName: "chunk-calendar" */ '@/pages/Calendar/CalendarHome.vue')

const CompanyContactHome = () =>
  import(
    /* webpackChunkName: "chunk-company-contact-home" */ '@/pages/CompanyContact/CompanyContactHome.vue'
  )

const CompanyContactEdit = () =>
  import(
    /* webpackChunkName: "chunk-company-contact-edit" */ '@/pages/CompanyContact/CompanyContactEdit.vue'
  )

const OpportunityHome = () =>
  import(/* webpackChunkName: "chunk-opportunities" */ '@/pages/Opportunity/OpportunityHome.vue')
const OpportunityEdit = () =>
  import(/* webpackChunkName: "chunk-opportunity-edit" */ '@/pages/Opportunity/OpportunityEdit.vue')

const CompanyDetails = () =>
  import(/* webpackChunkName: "chunk-company-details" */ '@/pages/Company/CompanyDetails.vue')
const NotificationAction = () =>
  import(
    /* webpackChunkName: "chunk-notification-action" */ '@/pages/Notification/NotificationAction.vue'
  )

const InventoryView = () =>
  import(/* webpackChunkName: "chunk-inventory-view" */ '@/pages/Inventory/InventoryView.vue')

const FaqHome = () => import(/* webpackChunkName: "chunk-faq-home" */ '@/pages/FAQ/FaqHome.vue')
const FaqEdit = () => import(/* webpackChunkName: "chunk-faq-edit" */ '@/pages/FAQ/FaqEdit.vue')

const GoalHome = () =>
  import(/* webpackChunkName: "chunk-goal-home" */ '@/pages/GoalPlanning/GoalPlanningHome.vue')
const GoalPlan = () =>
  import(/* webpackChunkName: "chunk-goal-plan" */ '@/pages/GoalPlanning/GoalPlan.vue')

const GoalOverView = () =>
  import(/* webpackChunkName: "chunk-goal-overview" */ '@/pages/GoalPlanning/OverviewDashboard.vue')

const GoalSnapshotView = () =>
  import(/* webpackChunkName: "chunk-goal-snapshot" */ '@/pages/GoalPlanning/GoalSnapshotView.vue')

const GoalReforecastView = () =>
  import(
    /* webpackChunkName: "chunk-goal-reforecast" */ '@/pages/GoalPlanning/GoalReforecastView.vue'
  )

const ReportBuilderHome = () =>
  import(
    /* webpackChunkName: "chunk-report-builder-home" */ '@/pages/ReportBuilder/ReportBuilderHome.vue'
  )

const ReportBuilderEdit = () =>
  import(
    /* webpackChunkName: "chunk-report-builder-edit" */ '@/pages/ReportBuilder/ReportBuilderEdit.vue'
  )
const ZipCodeLookup = () =>
  import(/* webpackChunkName: "chunk-zipcode-lookup" */ '@/pages/ZipCodeLookup/ZipCodeLookup.vue')

const RateCardHome = () =>
  import(/* webpackChunkName: "chunk-rate-card" */ '@/pages/RateCard/RateCardHome.vue')

const SystemToolsHome = () =>
  import(/* webpackChunkName: "chunk-system-tools-home" */ '@/pages/SystemTools/Index.vue')

const ManagementDashboards = () =>
  import(/* webpackChunkName: "chunk-mfa" */ '@/pages/ManagementDashboard/ManagementDashboard.vue')

const ManagementDashboardsV2 = () =>
  import(/* webpackChunkName: "chunk-mfa" */ '@/pages/ManagementDashboardV2/ManagementDashboard.vue')

const MFARequiredPage = () =>
  import(/* webpackChunkName: "chunk-mfa" */ '@/pages/MFARequiredPage.vue')

const Billboard = () =>
  import(/* webpackChunkName: "chunk-public-billboard" */ '@/pages/Billboard/Billboard.vue')

const BillboardPresetHome = () =>
  import(
    /* webpackChunkName: "chunk-billboard-preset" */ '@/pages/Billboard/BillboardPresetHome.vue'
  )
const BillboardPresetEdit = () =>
  import(
    /* webpackChunkName: "chunk-billboard-preset" */ '@/pages/Billboard/BillboardPresetEdit.vue'
  )

const BillboardHome = () =>
  import(/* webpackChunkName: "chunk-billboard" */ '@/pages/Billboard/BillboardHome.vue')
const BillboardEdit = () =>
  import(/* webpackChunkName: "chunk-billboard" */ '@/pages/Billboard/BillboardEdit.vue')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/mfa-required',
      name: 'mfa-required',
      component: MFARequiredPage,
      meta: {
        secure: true,
      },
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        secure: false,
      },
    },
    {
      path: '/unsubscribe/:id',
      name: 'Unsubscribe',
      component: Unsubscribe,
      props: true,
      meta: {
        secure: false,
      },
    },
    {
      path: '/billboard/:code?',
      name: 'billboard-view',
      component: Billboard,
      meta: {
        secure: false,
      },
    },
    {
      path: '/app',
      name: 'Layout',
      component: Layout,
      children: [
        {
          path: 'goals/snapshots/:year',
          name: 'GoalSnapshotView',
          component: GoalSnapshotView,
          props: true,
          meta: {
            secure: true,
            model: 'financial_plan',
            action: 'view',
            index: 'financial-planning',
          },
        },
        {
          path: 'goals/reforecast/:goal/:year',
          name: 'GoalReforecastView',
          component: GoalReforecastView,
          props: true,
          meta: {
            secure: true,
            model: 'financial_plan',
            action: 'view',
            index: 'financial-planning',
          },
        },
        {
          path: 'goals',
          name: 'GoalHome',
          component: GoalHome,
          meta: {
            secure: true,
            model: 'financial_plan',
            action: 'view',
            index: 'financial-planning',
          },
        },
        {
          path: 'goal/:year/:region?/:agency?',
          name: 'GoalPlan',
          component: GoalPlan,
          props: true,
          meta: {
            secure: true,
            model: 'financial_plan',
            action: 'view',
            index: 'financial-planning',
          },
        },
        {
          path: 'goal-overview/:year/:region?/:agency?',
          name: 'GoalOverView',
          component: GoalOverView,
          props: true,
          meta: {
            secure: true,
            model: 'management_dashboard',
            action: 'view',
            index: 'financial-planning',
          },
        },
        {
          path: 'management-dashboard',
          name: 'ManagementDashboards',
          component: ManagementDashboards,
          props: true,
          meta: {
            secure: true,
            model: 'management_dashboard',
            action: 'view',
            index: 'management_dashboard',
          },
        },
        {
          path: 'management-dashboard-v2',
          name: 'ManagementDashboardsv2',
          component: ManagementDashboardsV2,
          props: true,
          meta: {
            secure: true,
            model: 'management_dashboard',
            action: 'view',
            index: 'management_dashboard',
          },
        },

        // main pages
        {
          path: 'insights',
          name: 'Insights',
          component: Insights,
          meta: {
            secure: true,
            model: 'insights',
            action: 'view',
            index: 'insights',
          },
        },
        {
          path: 'adops/monitoring',
          name: 'adops',
          component: AdOps,
          meta: {
            secure: true,
            model: 'adops',
            action: 'view',
            index: 'order_monitoring',
          },
        },
        {
          path: 'adops/distribution',
          name: 'inventory_distribution',
          component: AdOpsDistribution,
          meta: {
            secure: true,
            model: 'adops',
            action: 'view',
            index: 'inventory_distribution',
          },
        },
        {
          path: 'insights',
          name: 'Dashboard',
          component: Insights,
          meta: {
            secure: true,
            model: 'insights',
            action: 'view',
            index: 'insights',
          },
        },
        {
          path: 'legacy-dashboard',
          name: 'LegacyDashboard',
          component: LegacyDashboard,
          meta: {
            secure: true,
            index: 'legacy-dashboard',
          },
        },
        {
          path: 'mediaocean_invoices',
          name: 'MediaOceanInvoiceHome',
          component: MediaOceanHome,
          meta: {
            secure: true,
            model: 'media_ocean',
            action: 'view',
            index: 'mediaocean_invoices',
          },
        },
        {
          path: 'mediaocean_invoice/:id?',
          props: true,
          name: 'MediaOceanInvoiceEdit',
          component: MediaOceanEdit,
          meta: {
            secure: true,
            model: 'media_ocean',
            action: 'edit',
            index: 'mediaocean_invoices',
          },
        },
        {
          path: 'forecast',
          name: 'ForecastHome',
          component: ForecastHome,
          meta: {
            secure: true,
            model: 'forecast',
            action: 'view',
            index: 'forecast',
          },
        },
        {
          path: 'forecast/update',
          name: 'ForecastUpdate',
          component: ForecastUpdate,
          meta: {
            secure: true,
            model: 'forecast',
            action: 'edit',
            index: 'forecast',
          },
        },
        {
          path: 'zipcode-lookup',
          name: 'ZipCodeLookup',
          component: ZipCodeLookup,
          meta: {
            secure: true,
            model: 'zipcode_lookup',
            action: 'view',
            index: 'zipcode-lookup',
          },
        },
        {
          path: 'rate-card',
          name: 'RateCardHome',
          component: RateCardHome,
          meta: {
            secure: true,
            model: 'rate_card',
            action: 'view',
            index: 'rate_card',
          },
        },
        {
          path: 'companies',
          name: 'Companies',
          component: CompanyHome,
          meta: {
            secure: true,
            model: 'company',
            action: 'view',
            index: 'companies',
          },
        },
        {
          path: 'company/:id?',
          name: 'Company',
          component: CompanyEdit,
          props: true,
          meta: {
            secure: true,
            model: 'company',
            action: 'edit',
            index: 'companies',
          },
        },
        {
          path: 'users',
          name: 'Users',
          component: UserHome,
          meta: {
            secure: true,
            model: 'user',
            action: 'view',
            index: 'users',
          },
        },
        {
          path: 'user/:id?',
          name: 'User',
          component: UserEdit,
          props: true,
          meta: {
            secure: true,
            model: 'user',
            action: 'edit',
            index: 'users',
          },
        },
        {
          path: 'profiles',
          name: 'Profiles',
          component: ProfileHome,
          meta: {
            secure: true,
            model: 'profile',
            action: 'view',
            index: 'profiles',
          },
        },
        {
          path: 'profile/:id?',
          name: 'Profile',
          component: ProfileEdit,
          props: true,
          meta: {
            secure: true,
            model: 'profile',
            action: 'edit',
            index: 'profiles',
          },
        },
        {
          path: 'account/:oauth?',
          name: 'Account',
          component: UserAccount,
          props: true,
          meta: {
            secure: true,
            index: 'account',
          },
        },
        {
          path: 'agreements',
          name: 'AgreementHome',
          component: AgreementHome,
          props: true,
          meta: {
            secure: true,
            model: 'agreement',
            action: 'view',
            index: 'agreements',
          },
        },
        {
          path: 'agreement/:id?',
          name: 'AgreementEdit',
          component: AgreementEdit,
          props: true,
          meta: {
            secure: true,
            model: 'agreement',
            action: 'edit',
            index: 'agreements',
          },
        },
        {
          path: 'terms',
          name: 'TermsHome',
          component: TermsHome,
          props: true,
          meta: {
            secure: true,
            model: 'term',
            action: 'view',
            index: 'terms',
          },
        },
        {
          path: 'term/:id?',
          name: 'TermsEdit',
          component: TermsEdit,
          props: true,
          meta: {
            secure: true,
            model: 'term',
            action: 'edit',
            index: 'terms',
          },
        },
        {
          path: 'reports',
          name: 'Reports',
          component: ReportHome,
          meta: {
            secure: true,
            model: 'report',
            action: 'edit',
            index: 'reports',
          },
        },
        {
          path: 'report/:id?',
          name: 'Report',
          component: ReportEdit,
          props: true,
          meta: {
            secure: true,
            model: 'report',
            action: 'view',
            index: 'reports',
          },
        },
        {
          path: 'invoice/review',
          name: 'ReviewInvoices',
          component: ReviewHome,
          meta: {
            secure: true,
            model: 'invoice',
            action: 'review',
            index: 'review_invoice',
          },
        },
        {
          path: 'invoices',
          name: 'Invoices',
          component: InvoiceHome,
          meta: {
            secure: true,
            model: 'invoice',
            action: 'view',
            index: 'invoices',
          },
        },
        {
          path: 'invoice/:id?',
          name: 'Invoice',
          component: InvoiceEdit,
          props: true,
          meta: {
            secure: true,
            model: 'invoice',
            action: 'edit',
            index: 'invoices',
          },
        },
        {
          path: 'invoice/:id/view',
          name: 'InvoiceView',
          component: InvoiceView,
          props: true,
          meta: {
            secure: true,
            model: 'invoice',
            action: 'view',
            index: 'invoices',
          },
        },
        {
          path: 'views',
          name: 'ReportViews',
          component: ReportViewHome,
          props: true,
          meta: {
            secure: true,
            model: 'report_view',
            action: 'view',
            index: 'views',
          },
        },
        {
          path: 'view/:id?',
          name: 'ReportView',
          component: ReportViewEdit,
          props: true,
          meta: {
            secure: true,
            model: 'report_view',
            action: 'edit',
            index: 'views',
          },
        },
        {
          path: 'creatives',
          name: 'Creatives',
          props: true,
          component: CreativeHome,
          meta: {
            secure: true,
            model: 'creative',
            action: 'view',
            index: 'creatives',
          },
        },
        {
          path: 'creative/:id?',
          name: 'CreativeEdit',
          props: true,
          component: CreativeEdit,
          meta: {
            secure: true,
            model: 'creative',
            action: 'edit',
            index: 'creatives',
          },
        },
        {
          path: 'schedulers',
          name: 'Schedulers',
          component: AdunitSchedulerHome,
          meta: {
            secure: true,
            model: 'scheduler',
            action: 'view',
            index: 'schedulers',
          },
        },
        {
          path: 'scheduler/:id?',
          name: 'Scheduler',
          props: true,
          component: AdunitSchedulerEdit,
          meta: {
            secure: true,
            model: 'scheduler',
            action: 'view',
            index: 'schedulers',
          },
        },
        {
          path: 'inventories',
          name: 'Inventories',
          component: InventoryHome,
          meta: {
            secure: true,
            model: 'inventory',
            action: 'view',
          },
        },
        {
          path: 'inventory/:id?',
          name: 'InventoryEdit',
          props: true,
          component: InventoryEdit,
          meta: {
            secure: true,
            model: 'inventory',
            action: 'edit',
            index: 'adunits',
          },
        },
        {
          path: 'inventory/:id/view',
          name: 'inventoryView',
          props: true,
          component: InventoryView,
          meta: {
            secure: true,
            model: 'inventory',
            action: 'edit',
            index: 'adunits',
          },
        },
        {
          path: 'sales/media_packages',
          name: 'MediaPackages',
          props: true,
          component: MediaPackageHome,
          meta: {
            secure: true,
            model: 'media_package',
            action: 'edit',
            index: 'media_packages',
          },
        },
        {
          path: 'sales/media_package/:id?',
          name: 'MediaPackageEdit',
          props: true,
          component: MediaPackageEdit,
          meta: {
            secure: true,
            model: 'inventory',
            action: 'edit',
            index: 'media_packages',
          },
        },
        {
          path: 'sales/media_plans',
          name: 'MediaPlans',
          props: true,
          component: MediaPlanHome,
          meta: {
            secure: true,
            model: 'media_plan',
            action: 'view',
            index: 'media_plan',
          },
        },
        {
          path: 'sales/media_plan/:id?',
          name: 'MediaPlan',
          props: true,
          component: MediaPlanEdit,
          meta: {
            secure: true,
            model: 'media_plan',
            action: 'edit',
            index: 'media_plan',
          },
        },
        {
          path: 'sales/media_plan/:id/view/:mode?',
          name: 'MediaPlanView',
          component: MediaPlanView,
          props: true,
          meta: {
            secure: true,
            model: 'media_plan',
            action: 'view',
            index: 'media_plan',
          },
        },
        {
          path: 'tasks/:preview?',
          name: 'TaskHome',
          props: true,
          component: TaskHome,
          meta: {
            secure: true,
            model: 'task',
            action: 'edit',
            index: 'tasks',
          },
        },
        {
          path: 'audit',
          name: 'Audit',
          component: AuditHome,
          meta: {
            secure: true,
            model: 'audit',
            action: 'view',
            index: 'adunits',
          },
        },
        {
          path: 'reconciliations',
          name: 'reconciliations',
          component: ReconciliationHome,
          meta: {
            secure: true,
            model: 'reconciliation',
            action: 'view',
            index: 'reconciliations',
          },
        },
        {
          path: 'reconciliation/:period',
          name: 'reconciliation-view',
          component: ReconciliationView,
          meta: {
            secure: true,
            model: 'reconciliation',
            action: 'edit',
            index: 'reconciliations',
          },
        },
        {
          path: 'sales/dynamic_rates',
          name: 'dynamic_rate',
          component: DynamicRateHome,
          meta: {
            secure: true,
            model: 'dynamic_rate',
            action: 'view',
            index: 'dynamic_rates',
          },
        },
        {
          path: 'sales/dynamic_rate/:id?',
          name: 'dynamic_rate_edit',
          component: DynamicRateView,
          meta: {
            secure: true,
            model: 'dynamic_rate',
            action: 'edit',
            index: 'dynamic_rates',
          },
        },
        {
          path: 'payments',
          name: 'payments',
          component: PaymentHome,
          meta: {
            secure: true,
            model: 'payment',
            action: 'view',
            index: 'payments',
          },
        },
        {
          path: 'payment/:id?',
          name: 'payment',
          component: PaymentView,
          meta: {
            secure: true,
            model: 'payment',
            action: 'view',
            index: 'payments',
          },
        },
        {
          path: 'invoice_batch',
          name: 'invoice-create-batch',
          component: InvoiceCreateBatch,
          meta: {
            secure: true,
            model: 'payment',
            action: 'view',
            index: 'invoice',
          },
        },
        {
          path: 'expenses',
          name: 'expenses',
          component: ExpenseHome,
          meta: {
            secure: true,
            model: 'expense',
            action: 'view',
            index: 'expenses',
          },
        },
        {
          path: 'expense/batch',
          name: 'expense-batch',
          component: ExpenseBatch,
          meta: {
            secure: true,
            model: 'expense',
            action: 'view',
            index: 'expenses',
          },
        },
        {
          path: 'expense/:id?',
          name: 'expense',
          component: ExpenseView,
          meta: {
            secure: true,
            model: 'expense',
            action: 'view',
            index: 'expenses',
          },
        },

        {
          path: 'credit-memos',
          name: 'credit-memos',
          component: CreditMemoHome,
          meta: {
            secure: true,
            model: 'memo',
            action: 'view',
            index: 'credit-memos',
          },
        },
        {
          path: 'credit-memo/:id?',
          name: 'credit-memo',
          component: CreditMemoEdit,
          meta: {
            secure: true,
            model: 'memo',
            action: 'edit',
            index: 'credit-memos',
          },
        },

        {
          path: 'journal-entries',
          name: 'journal-entries',
          component: JournalEntryHome,
          meta: {
            secure: true,
            model: 'journal',
            action: 'view',
            index: 'journal-entries',
          },
        },
        {
          path: 'journal-entry/:id?',
          name: 'journal-entry',
          component: JournalEntryEdit,
          meta: {
            secure: true,
            model: 'journal',
            action: 'edit',
            index: 'journal-entries',
          },
        },
        {
          path: 'system-parameters',
          name: 'system-parameters',
          component: SystemParameterHome,
          meta: {
            secure: true,
            model: 'system_config',
            action: 'view',
            index: 'system-parameters',
          },
        },
        {
          path: 'system-tools',
          name: 'system-tools',
          component: SystemToolsHome,
          meta: {
            secure: true,
            model: 'system_tools',
            action: 'view',
            index: 'system-tools',
          },
        },
        {
          path: 'adserver-inspector',
          name: 'adserver-inspector',
          component: AdserverInspector,
          meta: {
            secure: true,
            model: 'adserver',
            action: 'monitor',
            index: 'adserver-inspector',
          },
        },
        {
          path: 'orders',
          name: 'orders',
          component: OrdersHome,
          meta: {
            secure: true,
            model: 'adserver',
            action: 'view',
            index: 'orders',
          },
        },
        {
          path: 'line-item/:id?',
          name: 'line-item',
          component: LineItemEdit,
          meta: {
            secure: true,
            model: 'adserver',
            action: 'view',
            index: 'line-items',
          },
        },
        {
          path: 'line-items',
          name: 'line-items',
          component: LineItemHome,
          meta: {
            secure: true,
            model: 'adserver',
            action: 'view',
            index: 'line-items',
          },
        },
        {
          path: 'mediaplan-item-home',
          name: 'mediaplan-item-home',
          component: MediaPlanItemHome,
          meta: {
            secure: true,
            model: 'adserver',
            action: 'view',
            index: 'mediaplan-items',
          },
        },
        {
          path: 'mediaplan-item/:id?',
          name: 'mediaplan-item',
          component: MediaPlanItemEdit,
          meta: {
            secure: true,
            model: 'adserver',
            action: 'view',
            index: 'mediaplan-items',
          },
        },

        {
          path: 'order/:id?',
          name: 'order',
          component: OrdersEdit,
          meta: {
            secure: true,
            model: 'adserver',
            action: 'view',
            index: 'orders',
          },
        },
        {
          path: 'order/order_overview/:id?',
          name: 'order-overview',
          component: OrderView,
          meta: {
            secure: true,
            model: 'adserver',
            action: 'view',
            index: 'orders',
          },
        },
        {
          path: 'commissions',
          name: 'commissions',
          component: CommissionHome,
          meta: {
            secure: true,
            model: 'commission',
            action: 'view',
            index: 'commissions',
          },
        },
        {
          path: 'commission-rules',
          name: 'commission-rules',
          component: CommissionRules,
          meta: {
            secure: true,
            model: 'commission',
            action: 'view',
            index: 'commission-rules',
          },
        },

        {
          path: 'commission-rule/:id?',
          name: 'commission-rule',
          component: CommissionRuleEdit,
          meta: {
            secure: true,
            model: 'commission',
            action: 'view',
            index: 'commission-rules',
          },
        },

        {
          path: 'commission/:id?',
          name: 'commission',
          component: CommissionEdit,
          meta: {
            secure: true,
            model: 'commission',
            action: 'view',
            index: 'commissions',
          },
        },
        {
          path: 'opportunities',
          name: 'opportunities',
          component: OpportunityHome,
          meta: {
            secure: true,
            model: 'opportunity',
            action: 'view',
            index: 'opportunities',
          },
        },
        {
          path: 'opportunity/:id?',
          name: 'opportunity',
          component: OpportunityEdit,
          meta: {
            secure: true,
            model: 'opportunity',
            action: 'view',
            index: 'opportunities',
          },
        },
        {
          path: 'calendar',
          name: 'calendar-home',
          component: CalendarHome,
          meta: {
            secure: true,
            model: 'commission',
            action: 'view',
            index: 'commissions',
          },
        },
        {
          path: 'company-contacts',
          name: 'company-contacts',
          component: CompanyContactHome,
          meta: {
            secure: true,
            model: 'opportunity',
            action: 'view',
            index: 'company-contacts',
          },
        },
        {
          path: 'company-contact/:id?',
          name: 'company-contact',
          component: CompanyContactEdit,
          meta: {
            secure: true,
            model: 'opportunity',
            action: 'view',
            index: 'company-contacts',
          },
        },
        {
          path: 'company/:id/details',
          name: 'company-details',
          component: CompanyDetails,
          meta: {
            secure: true,
            model: 'company',
            action: 'view',
            index: 'companies',
          },
        },
        {
          path: 'sales/pre-plans',
          name: 'pre-plans',
          component: PrePlanHome,
          meta: {
            secure: true,
            model: 'max_avails_plan',
            action: 'view',
            index: 'preplans',
          },
        },
        {
          path: 'sales/pre-plan-convert/:id?',
          name: 'preplan-convert',
          component: PrePlanConvert,
          meta: {
            secure: true,
            model: 'max_avails_plan',
            action: 'edit',
            index: 'preplans',
          },
        },
        {
          path: 'sales/pre-plan/:id?',
          name: 'preplan',
          component: PrePlan,
          meta: {
            secure: true,
            model: 'max_avails_plan',
            action: 'edit',
            index: 'preplans',
          },
        },
        {
          path: 'inventory-notifications',
          name: 'inventory-notifications',
          component: NotificationAction,
          props: true,
          meta: {
            secure: true,
            model: 'inventory',
            action: 'view',
            index: 'inventory',
          },
        },
        {
          path: 'faq-home',
          name: 'faq-home',
          props: true,
          component: FaqHome,
          meta: {
            secure: true,
            model: 'faq',
            action: 'edit',
            index: 'faq',
          },
        },
        {
          path: 'faq/:id?',
          name: 'faq',
          props: true,
          component: FaqEdit,
          meta: {
            secure: true,
            model: 'faq',
            action: 'edit',
            index: 'faq',
          },
        },
        {
          path: 'report-builders',
          name: 'report-builders',
          props: true,
          component: ReportBuilderHome,
          meta: {
            secure: true,
            model: 'report',
            action: 'view',
            index: 'report-builder',
          },
        },
        {
          path: 'report-builder/:id?',
          name: 'report-builder',
          props: true,
          component: ReportBuilderEdit,
          meta: {
            secure: true,
            model: 'report',
            action: 'view',
            index: 'report-builder',
          },
        },
        {
          path: 'billboard-presets',
          name: 'billboard-presets',
          component: BillboardPresetHome,
          meta: {
            secure: true,
            model: 'billboard',
            action: 'edit',
            index: 'billboard-presets',
          },
        },
        {
          path: 'billboard-preset/:id?',
          name: 'billboard-preset',
          component: BillboardPresetEdit,
          meta: {
            secure: true,
            model: 'billboard',
            action: 'edit',
            index: 'billboard-presets',
          },
        },

        {
          path: 'billboards',
          name: 'billboards',
          component: BillboardHome,
          meta: {
            secure: true,
            model: 'billboard',
            action: 'view',
            index: 'billboards',
          },
        },
        {
          path: 'billboard/:id?',
          name: 'billboard',
          component: BillboardEdit,
          meta: {
            secure: true,
            model: 'billboard',
            action: 'edit',
            index: 'billboards',
          },
        },

        // ReportBuilder
      ],
    },
    {
      path: '*',
      name: 'Error',
      component: ErrorPage,
      meta: {
        secure: false,
      },
    },
    {
      path: '/404',
      name: '404',
      component: ErrorPage,
      meta: {
        secure: false,
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  let auth = false
  const systemModule = getModule(SystemtModule)
  if (systemModule.user) {
    auth = true
  }
  if (to.name === 'mfa-required' && systemModule.user?.mfa_enabled) {
    next({ name: 'Account' })
  }
  if (to.meta?.secure) {
    if (!auth) {
      if (to.name != 'Login') {
        systemModule.updateState({
          name: 'target',
          data: to,
        })

        next({ name: 'Login' })
      }
    } else if (to.name == 'Login') {
      let home = systemModule.user?.home
      if (!home) home = 'Dashboard'

      if (home.includes('/')) {
        next({ path: home })
      } else {
        next({ name: home })
      }
      MfaAlert(systemModule.user, next)
    } else if (!to.meta.model || systemModule.user?.can(to.meta.model, to.meta.action)) {
      next()
      if (to.name !== 'Account') MfaAlert(systemModule.user, next)
    } else {
      next({ name: '404' })
    }
  } else {
    next()
  }
})

export default router
